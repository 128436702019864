/**
 * @fileOverview Main Application Entry Point
 */

import cls from 'classnames'
import { useSelector } from 'react-redux'
import { Router as R } from 'react-router-dom'
import AppMain from './AppMain'
import AuthPollingEffects from './AuthPollingEffects'
import { AppScrollHelper, AuthRedirect, BrowserInfo, DesktopNav, MobileNav } from './components'
import ConnectivityHandler from './components/ConnectivityHandler'
import CookieNotice from './components/CookieNotice'
import LocaleWatcher from './components/LocaleWatcher'
import RouteWatcher from './components/RouteWatcher'
import { isSignupPage$ } from './compound-selectors/routes'
import { AppHistory } from './history'
import { TestID } from './TestID'
import { NavBarOutlet, NavProvider, OverlayProvider, ToastContainer, ToastManager } from './widgets'

const Router: any = R

export const App = () => {
	const isSignUpPage = useSelector(isSignupPage$)

	return (
		<main className={cls(!isSignUpPage && 'app-width')} data-testid={TestID.CLICK_OFF_APP_TID$$}>
			<Router history={AppHistory}>
				<OverlayProvider>
					<AppScrollHelper>
						<NavProvider>
							<ToastManager>
								<NavBarOutlet />
								<DesktopNav />
								<MobileNav />
								<AppMain />
								<CookieNotice />
								<ToastContainer />

								<AuthPollingEffects />
								<AuthRedirect />
								<BrowserInfo />
								<ConnectivityHandler />
								<LocaleWatcher />
								<RouteWatcher />
							</ToastManager>
						</NavProvider>
					</AppScrollHelper>
				</OverlayProvider>
			</Router>
		</main>
	)
}
