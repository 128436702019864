import { assertNotNullish, setIfNotEqual } from '@eturi/util'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { resetAction } from '../actions'
import { bindCreateAsyncThunkToState } from '../bindCreateAsyncThunkToState'
import type { HttpExtra } from '../http'
import type { AccountBenefit, BenefitGroup, InitState, SThunkState } from '../types'

export type BenefitsState = InitState & {
	readonly availableBenefit: Maybe<AccountBenefit>
	readonly benefitGroup: Maybe<BenefitGroup>
	readonly redeemedBenefit: Maybe<AccountBenefit>
}

export type WithBenefitsState = {
	readonly benefits: BenefitsState
}

const initialState: BenefitsState = {
	availableBenefit: null,
	benefitGroup: null,
	isInit: false,
	redeemedBenefit: null,
}

export const benefitsSlice = /*@__PURE__*/ createSlice({
	name: 'benefits',
	initialState,
	reducers: {
		// FIXME: Currently only used for testing
		setAvailableBenefit(s, a: PayloadAction<Maybe<AccountBenefit>>) {
			setIfNotEqual(s, 'availableBenefit', a.payload)
		},

		// FIXME: Currently only used for testing and clearing benefit
		setBenefitGroup(s, a: PayloadAction<Maybe<BenefitGroup>>) {
			setIfNotEqual(s, 'benefitGroup', a.payload)
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(resetAction, () => initialState)
			.addCase(fetchCouponDetails.fulfilled, (s, a) => {
				setIfNotEqual(s, 'availableBenefit', a.payload)
				s.isInit = true
			})
			.addCase(fetchRedeemedCouponDetails.fulfilled, (s, a) => {
				setIfNotEqual(s, 'redeemedBenefit', a.payload)
			})
			.addCase(fetchGroupDetails.fulfilled, (s, a) => {
				setIfNotEqual(s, 'benefitGroup', a.payload)
			}),
})

export const { setAvailableBenefit, setBenefitGroup } = benefitsSlice.actions

////////// Thunks //////////////////////////////////////////////////////////////

type BenefitsThunkState = SThunkState & WithBenefitsState

const createAsyncThunk = /*@__PURE__*/ bindCreateAsyncThunkToState<BenefitsThunkState>()

export const fetchCouponDetails = /*@__PURE__*/ createAsyncThunk(
	'benefits/fetchCouponDetails',
	async (extra: HttpExtra = {}, { dispatch, extra: { http } }) => {
		// Fetch both available and redeemed benefits
		const availableBenefits = await dispatch(
			http.get<Maybe<AccountBenefit>[]>('/coupon_details?query_type=account', extra),
		)

		assertNotNullish(availableBenefits, 'Maybe<AccountBenefit>[]')

		return availableBenefits[0]
	},
	{
		condition: (arg, api) => {
			if (!arg?.force && isBenefitsInit$(api.getState())) return false
		},
	},
)

export const fetchRedeemedCouponDetails = /*@__PURE__*/ createAsyncThunk(
	'benefits/fetchRedeemedCouponDetails',
	async (extra: HttpExtra = {}, { dispatch, extra: { http } }) => {
		// Fetch redeemed benefits
		const redeemedBenefit = await dispatch(
			http.get<Maybe<AccountBenefit>[]>('/coupon_details?query_type=redeemed', extra),
		)

		assertNotNullish(redeemedBenefit, 'Maybe<AccountBenefit>[]')

		return redeemedBenefit[0]
	},
	{
		condition: (arg, api) => {
			if (!arg?.force && isBenefitsInit$(api.getState())) return false
		},
	},
)

type FetchGroupDetailsArg = {
	readonly groupId: string
	readonly sfsh: string
}

export const fetchGroupDetails = /*@__PURE__*/ createAsyncThunk(
	'benefits/fetchGroupDetails',
	async ({ groupId, sfsh }: FetchGroupDetailsArg, { dispatch, extra: { http } }) => {
		const group = await dispatch(
			http.get<Maybe<BenefitGroup>>(`${sfsh}/group_details?group_id=${groupId}`, {
				isUnauthenticated: true,
			}),
		)

		// NOTE: This endpoint will always return at least the {group_id} that
		//  is passed to it, so we need to determine whether it's valid using
		//  scopes.
		assertNotNullish(group?.scopes, 'BenefitGroup')

		return group
	},
)

////////// Selectors ///////////////////////////////////////////////////////////

const state$ = <T extends WithBenefitsState>(s: T) => s.benefits

export const availableBenefit$ = /*@__PURE__*/ createSelector(state$, (s) => s.availableBenefit)
export const benefitGroup$ = /*@__PURE__*/ createSelector(state$, (s) => s.benefitGroup)
export const redeemedBenefit$ = /*@__PURE__*/ createSelector(state$, (s) => s.redeemedBenefit)
export const benefitGroupCouponCode$ = /*@__PURE__*/ createSelector(
	benefitGroup$,
	(g) => g?.coupon_code,
)
export const benefitGroupId$ = /*@__PURE__*/ createSelector(benefitGroup$, (g) => g?.group_id)
export const benefitWorkflow$ = /*@__PURE__*/ createSelector(benefitGroup$, (g) => g?.workflow)
export const benefitGroupLogo$ = /*@__PURE__*/ createSelector(benefitGroup$, (g) => g?.logo_url)
export const isBenefitsInit$ = /*@__PURE__*/ createSelector(state$, (s) => s.isInit)
export const isGroupSignUpAllowed$ = /*@__PURE__*/ createSelector(
	benefitGroup$,
	(g) => !!g?.allow_signup,
)
export const activeBenefit$ = /*@__PURE__*/ createSelector(
	availableBenefit$,
	redeemedBenefit$,
	(available, redeemed) => redeemed || available,
)
