import { useConstant } from '@eturi/react'
import { useEffect } from 'react'
import { useNavTo } from './useNavTo'
import { useSearchParams } from './useSearchParams'

export const useSearchParamsAndClear = <T>(getSearchParams: (params: URLSearchParams) => T) => {
	const navTo = useNavTo()
	const searchParams = useSearchParams()
	const ret = useConstant(() => getSearchParams(searchParams))

	useEffect(() => {
		navTo({ search: '' }, true)
	}, [])

	return ret
}
