/**
 * @fileOverview Docs: https://parentsware.atlassian.net/wiki/spaces/ED/pages/783745029/Coupons+and+Groups
 */

import type { SKUDef } from './SKUDef'
import { intToFloatPrice, toPriceStr } from './SKUDef'

export type BenefitApplyOnly = 'existing' | 'signup' | 'signup,existing'

// The current state of the coupon
export type BenefitStates = 'exhausted' | 'expired' | 'locked' | 'ready' | 'revoked'

/*
 * - keep or undefined: discount is retained across subscriptions
 * - remove: changes will remove the discount
 * - check: server checks and coupon benefits are re-evaluated against the new SKU and coupon
 */
export type StripeDiscountBenefitChangeEffect = 'keep' | 'remove' | 'check'

/*
 * Benefit Change Effect
 * - keep or undefined: extended trial is kept even if sku is not in limit skus
 * - remove: extended trial on subscription is reset to standard default trial if they've
 *  already used up that time then payment starts immediately.
 * - check: server checks to see if extended trial can be applied to new sku and does so if possible
 * - keep_downgrade: extended trial is kept for all changes except upgrade but the coupon is removed
 * - keep_downgrade_check: extended trial is kept for all changes except upgrade. If user goes
 *  back to a sku within limit_skus, then trial is re-established
 * - deny: changes to subscription sku is not allowed
 * - deny_other: changes to sku not within limit_skus is not allowed
 */
export type StripeTrialBenefitChangeEffect =
	| 'keep'
	| 'remove'
	| 'check'
	| 'keep_downgrade'
	| 'keep_downgrade_check'
	| 'deny'
	| 'deny_other'

/*
 * For Extended trials this determines what happens if coupon is applied after a trial is changed
 * - new: coupon is ignored (same if key is missing)
 * - trial: extends the trial to the new trial duration
 * - paid: if the user has paid then the subscription trial will start and the user will be prorated
 * - always: Same as (trial or paid) regardless if they have paid or started a trial they will get the coupon
 */
export type StripeTrialApplyEffect = 'always' | 'trial' | 'paid' | 'new'

export type BenefitAccountUpgrade = {
	// How long, in days, the SKU will be granted for
	readonly grant_days: number

	// The name of the SKU granted
	readonly grant_sku: string
}

export type BenefitExtendedTrial = {
	// Number of this benefit that are available
	readonly available_count: number

	// The number of seconds that this benefit will be available to the account
	// holder after it is redeemed. Basically, how long they have until they
	// need to initiate the Stripe purchase in order to get the deal. 0 for
	// unlimited.
	readonly available_secs?: number

	// May be limited to certain SKUs
	readonly limit_skus?: string[]

	// The length of the trial being granted in seconds
	readonly trial_secs: number
	readonly sku_benefits: SKUBenefits

	readonly stripe_on_change?: StripeTrialBenefitChangeEffect
	readonly stripe_apply_when?: StripeTrialApplyEffect
	// List of groups the benefit belongs to
	readonly limit_groups?: string[]
}

export type BenefitGroupMembership = {
	// Links the user to a given group
	readonly group_id: string

	// This link may expire at some point
	readonly group_secs: number
}

type SKUBenefit = {
	readonly stripe_code: string
	readonly stripe_percent_off: number
	readonly trial_secs: number
}

type SKUBenefits = {
	readonly [sku: string]: SKUBenefit
}

export type BenefitStripeCoupon = Pick<
	BenefitExtendedTrial,
	'available_count' | 'available_secs' | 'limit_skus'
> & {
	// This is an exception or override of the default `BenefitStripeCoupon`
	readonly sku_benefits: SKUBenefits
	readonly stripe_code: string // INTERNAL use
	readonly stripe_percent_off?: number
	readonly stripe_duration_in_months?: number
	readonly stripe_on_change?: StripeDiscountBenefitChangeEffect
}

export type BenefitScope = ValueUnion<typeof BenefitScope>
export const BenefitScope = {
	// Upgrade an account
	ACCOUNT_UPGRADE: 'ourpact.account_upgrade',

	// Grant a longer Stripe trial period
	EXTENDED_TRIAL: 'ourpact.extended_trial',

	// Grant group membership
	GROUP_MEMBERSHIP: 'ourpact.group_membership',

	// Apply a Stripe coupon code on subscribe
	STRIPE_COUPON: 'ourpact.stripe_coupon',
} as const

export type BenefitType = 'coupon' | 'group'

type BenefitCouponBenefits = {
	readonly limit_skus: string[]
	readonly scopes: BenefitScope[]
	readonly stripe_code: string
}

/**
 * Defines pre-determined client workflow behavior
 * 'affiliate_promo' when the promo is part of an affiliate promo group
 * 'affiliate_1tc_promo' is similar to affiliate_promo but requires a 1 time
 * coupon to be entered at sign up. Example: hsn/redeem group
 */
export type BenefitWorkflow = 'affiliate_promo' | 'affiliate_1tc_promo'

export type BaseBenefitGroup = {
	readonly allow_signup?: boolean
	readonly coupon_benefits?: Maybe<BenefitCouponBenefits>
	readonly coupon_code?: string
	readonly discount_header?: string
	// NOTE: This may be added to help with testing. The `group_alias`, would be used as the
	//  `group_id` for the purpose of matching hard-coded affiliates. This way a group can be added
	//  that tests the display and behavior of a known affiliate, but has parameters that allow for
	//  easier testing (e.g. low expiration or trial limits).
	// readonly group_alias?: string
	readonly group_id: string
	readonly logo_url?: string
	readonly scopes: BenefitScope[]
	readonly workflow?: BenefitWorkflow
}

export type AccountUpgradeGroup = BaseBenefitGroup & {
	readonly coupon_benefits: BenefitAccountUpgrade
}
export type ExtendedTrialGroup = BaseBenefitGroup & {
	readonly coupon_benefits: BenefitExtendedTrial
}
export type GroupMembershipGroup = BaseBenefitGroup & {
	readonly coupon_benefits: BenefitGroupMembership
}
export type StripeCouponGroup = BaseBenefitGroup & { readonly coupon_benefits: BenefitStripeCoupon }

export type BenefitGroup =
	| BaseBenefitGroup
	| AccountUpgradeGroup
	| ExtendedTrialGroup
	| GroupMembershipGroup
	| StripeCouponGroup

export type BaseBenefitDetails = {
	readonly apply_only: BenefitApplyOnly
	readonly scopes: BenefitScope[]
}

export type BenefitDetailsAccountUpgrade = BaseBenefitDetails & BenefitAccountUpgrade
export type BenefitDetailsExtendedTrial = BaseBenefitDetails & BenefitExtendedTrial
export type BenefitDetailsStripe = BaseBenefitDetails & BenefitStripeCoupon
export type BenefitDetailsGroup = BaseBenefitDetails & BenefitGroupMembership

export type BenefitDetails =
	| BenefitDetailsAccountUpgrade
	| BenefitDetailsExtendedTrial
	| BenefitDetailsGroup
	| BenefitDetailsStripe

export type SystemBenefit = {
	readonly created_ts: number // Not used
	readonly details: BenefitDetails
	readonly expiry_ts: Maybe<number>
	// The coupon code
	readonly key: string
	readonly state: BenefitStates
	readonly type: BenefitType
	readonly updated_ts: number // Not used
}

export type AccountBenefitInfo = {
	// When the benefit was applied to the account
	readonly applied_ts: number
	// If defined, benefit is associated with this certain group
	readonly coupon_group_id?: string
	readonly coupon_registration?: string
	// Not used. This would be when the coupon itself expires. Once it's applied
	// to an account though, this is irrelevant. To calculate how long the user
	// has to redeem we use `applied_ts` and `available_secs`
	readonly expiry_ts: Maybe<number>
	readonly type: BenefitType
}

export type AccountBenefit = SystemBenefit & { readonly account: AccountBenefitInfo }

const hasBenefitInScope = (b: { scopes: BenefitScope[] }, scope: BenefitScope) =>
	b.scopes.includes(scope)

/**
 * These are called on return from '/coupon_details': `AccountBenefit` details.
 * @see AccountBenefit
 * @example
 * ```
 * const accountBenefit = accountBenefit$(state);
 * const details = get(accountBenefit, 'details');
 * const isExtended = details && isExtendedTrialBenefitDetails(details);
 * ```
 */
const isBenefitDetailScope =
	<T extends BenefitDetails>(s: BenefitScope) =>
	<B extends BaseBenefitDetails>(b?: B): b is B & T =>
		!!b && hasBenefitInScope(b, s)

const isAccountBenefitDetails =
	<T extends BenefitDetails>(scope: BenefitScope) =>
	<B extends AccountBenefit>(b: Maybe<B>): b is B & { readonly details: T } =>
		!!b && hasBenefitInScope(b.details, scope)

/**
 * This is called on return from group_details: `BenefitGroup`.
 * @see BenefitGroup
 * @example
 * ```
 * const benefitGroup = benefitGroup$(state);
 * const isExtended = benefitGroup && isExtendedTrialGroup(benefitGroup);
 * ```
 */
//const hasBenefitInGroup =
//	<T extends BenefitGroup>(s: BenefitScope) =>
//	<B extends BaseBenefitGroup>(b: Maybe<B>): b is B & T =>
//		!!b?.coupon_benefits && hasBenefitInScope(b, s)

//export const isAccountUpgradeBenefitDetails =
//	/*@__PURE__*/ isBenefitDetailScope<BenefitDetailsAccountUpgrade>(BenefitScope.ACCOUNT_UPGRADE)
export const isExtendedTrialBenefitDetails =
	/*@__PURE__*/ isBenefitDetailScope<BenefitDetailsExtendedTrial>(BenefitScope.EXTENDED_TRIAL)
//export const isGroupBenefitDetails = /*@__PURE__*/ isBenefitDetailScope<BenefitDetailsGroup>(
//	BenefitScope.GROUP_MEMBERSHIP,
//)
export const isStripeBenefitDetails = /*@__PURE__*/ isBenefitDetailScope<BenefitDetailsStripe>(
	BenefitScope.STRIPE_COUPON,
)

//export const isAccountUpgradeBenefit =
//	/*@__PURE__*/ isAccountBenefitDetails<BenefitDetailsAccountUpgrade>(BenefitScope.ACCOUNT_UPGRADE)
export const isExtendedTrialBenefit =
	/*@__PURE__*/ isAccountBenefitDetails<BenefitDetailsExtendedTrial>(BenefitScope.EXTENDED_TRIAL)
//export const isGroupBenefit = /*@__PURE__*/ isAccountBenefitDetails<BenefitDetailsGroup>(
//	BenefitScope.GROUP_MEMBERSHIP,
//)
export const isStripeCouponBenefit = /*@__PURE__*/ isAccountBenefitDetails<BenefitDetailsStripe>(
	BenefitScope.STRIPE_COUPON,
)

//export const isAccountUpgradeGroup = /*@__PURE__*/ hasBenefitInGroup<AccountUpgradeGroup>(
//	BenefitScope.ACCOUNT_UPGRADE,
//)
//export const isExtendedTrialGroup = /*@__PURE__*/ hasBenefitInGroup<ExtendedTrialGroup>(
//	BenefitScope.EXTENDED_TRIAL,
//)
//export const isGroupMembershipGroup = /*@__PURE__*/ hasBenefitInGroup<GroupMembershipGroup>(
//	BenefitScope.GROUP_MEMBERSHIP,
//)
//export const isStripeCouponGroup = /*@__PURE__*/ hasBenefitInGroup<StripeCouponGroup>(
//	BenefitScope.STRIPE_COUPON,
//)

const getSKUPriceWithBenefit = (
	benefit: Maybe<AccountBenefit>,
	sku: Maybe<SKUDef>,
	isStripe: boolean,
) => {
	const discountMod =
		(
			isStripe &&
			isStripeCouponBenefit(benefit) &&
			sku &&
			benefit.details.limit_skus?.includes(sku.sku)
		) ?
			(100 - (benefit.details.stripe_percent_off || 0)) / 100
		:	1

	return intToFloatPrice(discountMod * (sku?.price_usd || 0))
}

export const getSkuPriceStrWithBenefitBreakdown = (
	activeBenefit: Maybe<AccountBenefit>,
	sku: Maybe<SKUDef>,
	isStripe: boolean,
) =>
	toPriceStr(
		getSKUPriceWithBenefit(activeBenefit, sku, isStripe) /
			(sku?.billing_cycle === 'annual' ? 12 : 1),
	)

export const getSKUPriceStrWithBenefit = (
	activeBenefit: Maybe<AccountBenefit>,
	sku: Maybe<SKUDef>,
	isStripe: boolean,
) => toPriceStr(getSKUPriceWithBenefit(activeBenefit, sku, isStripe))
