import {
	activeBenefit$,
	availableBenefit$,
	getSKUPriceStrWithBenefit,
	getSkuPriceStrWithBenefitBreakdown,
	isExtendedTrialBenefit,
	isPlatformSKU,
	isStripeCouponBenefit,
	mapTierToFullName,
	mapTierToName,
	nextSKU$,
	type PaidTier,
	redeemedBenefit$,
	type SKUDef,
	skuDefs$,
	stripeSKUDefs$,
	stripeSKUPrem$,
	stripeSKUPremPlus$,
} from '@op/services'
import { createSelector } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'
import { subscribingCycle$ } from '../reducers/product.slice'
import { isSubscribingStripe$, subscribingSKU$ } from './product'

export const subscribingPriceStr$ = /*@__PURE__*/ createSelector(
	activeBenefit$,
	subscribingSKU$,
	isSubscribingStripe$,
	getSKUPriceStrWithBenefit,
)

// Returns the highest tier sku they have a benefit for
export const highestBenefitSKU$ = /*@__PURE__*/ createSelector(
	activeBenefit$,
	stripeSKUDefs$,
	stripeSKUPremPlus$,
	(activeBenefit, stripeSKUDefs, stripeSKUPremPlus): Maybe<SKUDef> => {
		// FIXME: Product needs to handle the case where we have both available and redeemed on
		//  one account, for sake of rushed promo we are using redeemed as first choice
		if (!(isExtendedTrialBenefit(activeBenefit) || isStripeCouponBenefit(activeBenefit))) return

		const benefitSKUs = activeBenefit.details.limit_skus

		if (isEmpty(benefitSKUs)) return stripeSKUPremPlus

		// `benefitSKUs` is an array if it's not empty, which we checked above
		return benefitSKUs!.reduce((subscribingSKU: Maybe<SKUDef>, sku) => {
			const newDef = stripeSKUDefs.find((s) => s.sku === sku)

			if (!subscribingSKU) return newDef

			return (
					newDef &&
						(newDef.weight > subscribingSKU.weight ||
							(newDef.weight === subscribingSKU.weight && newDef.billing_cycle == 'monthly'))
				) ?
					newDef
				:	subscribingSKU
		}, null)
	},
)

// Returns all skus with a benefit available
export const availableBenefitSKUs$ = /*@__PURE__*/ createSelector(
	activeBenefit$,
	skuDefs$,
	(activeBenefit, skuDefs): SKUDef[] => {
		if (!(isExtendedTrialBenefit(activeBenefit) || isStripeCouponBenefit(activeBenefit))) return []

		const benefitSKUs = activeBenefit.details.limit_skus

		if (isEmpty(benefitSKUs)) return []

		// Get available SKUs for the benefit
		const stripeSKUs = skuDefs.filter((s) => isPlatformSKU(s, 'stripe'))

		// `benefitSKUs` is an array if it's not empty, which we checked above
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
		return stripeSKUs.filter((sku) => benefitSKUs!.includes(sku.sku))
	},
)

export const hasPremBenefitSKU$ = /*@__PURE__*/ createSelector(
	availableBenefitSKUs$,
	stripeSKUPrem$,
	(availableBenefitSKUs, premSKU) => availableBenefitSKUs.some((s) => s.tier === premSKU.tier),
)

export const hasPremPlusBenefitSKU$ = /*@__PURE__*/ createSelector(
	availableBenefitSKUs$,
	stripeSKUPremPlus$,
	(availableBenefitSKUs, premPlusSKU) =>
		availableBenefitSKUs.some((s) => s.tier === premPlusSKU.tier),
)

export const subscribingPriceBreakdown$ = /*@__PURE__*/ createSelector(
	activeBenefit$,
	subscribingSKU$,
	isSubscribingStripe$,
	getSkuPriceStrWithBenefitBreakdown,
)

export const selectedBenefitSKU$ = /*@__PURE__*/ createSelector(
	availableBenefitSKUs$,
	subscribingSKU$,
	subscribingCycle$,
	(availableBenefits, subSKU, subCycle): Maybe<SKUDef> => {
		if (isEmpty(availableBenefits) || !(subSKU && subCycle)) return null

		return availableBenefits.find((s) => s.sku === subSKU.sku)
	},
)

export const selectedSKUHasBenefit$ = /*@__PURE__*/ createSelector(selectedBenefitSKU$, (sku) =>
	Boolean(sku),
)

export const selectedBenefitSKUCycle$ = /*@__PURE__*/ createSelector(
	selectedBenefitSKU$,
	(s) => s?.billing_cycle,
)
export const selectedBenefitSKUId$ = /*@__PURE__*/ createSelector(
	selectedBenefitSKU$,
	(s) => s?.sku || '',
)
export const selectedBenefitSKUTier$ = /*@__PURE__*/ createSelector(
	selectedBenefitSKU$,
	(s) => s?.tier_def as Maybe<PaidTier>,
)
export const selectedBenefitSKUName$ = /*@__PURE__*/ createSelector(
	selectedBenefitSKUTier$,
	mapTierToName,
)
export const selectedBenefitSKUFullName$ = /*@__PURE__*/ createSelector(
	selectedBenefitSKUTier$,
	mapTierToFullName,
)

export const selectedBenefitTierHasMonthly$ = /*@__PURE__*/ createSelector(
	availableBenefitSKUs$,
	selectedBenefitSKU$,
	(availableBenefits, selectedSKU) => {
		if (!selectedSKU) return false

		if (selectedSKU.billing_cycle === 'monthly') return true

		return Boolean(
			availableBenefits.find(
				(sku) => sku.tier === selectedSKU.tier && sku.billing_cycle === 'monthly',
			),
		)
	},
)

export const selectedBenefitTierHasAnnual$ = /*@__PURE__*/ createSelector(
	availableBenefitSKUs$,
	selectedBenefitSKU$,
	(availableBenefits, selectedSKU) => {
		if (!selectedSKU) return false

		if (selectedSKU.billing_cycle === 'annual') return true

		return Boolean(
			availableBenefits.find(
				(sku) => sku.tier === selectedSKU.tier && sku.billing_cycle === 'annual',
			),
		)
	},
)

export const highestBenefitSKUCycle$ = /*@__PURE__*/ createSelector(
	highestBenefitSKU$,
	(s) => s?.billing_cycle,
)
export const highestBenefitSKUTier$ = /*@__PURE__*/ createSelector(
	highestBenefitSKU$,
	(s) => s?.tier_def as Maybe<PaidTier>,
)

export const subscribingSKUBenefit$ = /*@__PURE__*/ createSelector(
	subscribingSKU$,
	redeemedBenefit$,
	(subSKU, redeemedBenefit) => {
		if (!(subSKU && redeemedBenefit)) return

		if (
			isStripeCouponBenefit(redeemedBenefit) &&
			redeemedBenefit.details.limit_skus?.includes(subSKU.sku)
		) {
			return redeemedBenefit
		}
	},
)

export const nextSKUBenefit$ = /*@__PURE__*/ createSelector(
	nextSKU$,
	availableBenefit$,
	(nextSKU, availableBenefit) => {
		if (!(nextSKU && availableBenefit)) return

		if (
			isStripeCouponBenefit(availableBenefit) &&
			availableBenefit.details.limit_skus?.includes(nextSKU.sku)
		) {
			return availableBenefit
		}
	},
)

export const isIgnoringBenefit$ = /*@__PURE__*/ createSelector(
	subscribingSKU$,
	availableBenefit$,
	(subscribingSKU, availableBenefit) => {
		if (!(availableBenefit && subscribingSKU)) return false

		// If the user has an available stripe benefit and they are ignoring it then this will return
		// true
		return (
			(isStripeCouponBenefit(availableBenefit) || isExtendedTrialBenefit(availableBenefit)) &&
			!availableBenefit.details.limit_skus?.includes(subscribingSKU.sku)
		)
	},
)

export const hasRedeemedExtendedTrialBenefit$ = /*@__PURE__*/ createSelector(
	redeemedBenefit$,
	(redeemedBenefit) => redeemedBenefit && isExtendedTrialBenefit(redeemedBenefit),
)

export const hasRedeemedStripeBenefit$ = /*@__PURE__*/ createSelector(
	redeemedBenefit$,
	(redeemedBenefit) =>
		redeemedBenefit &&
		(isStripeCouponBenefit(redeemedBenefit) || isExtendedTrialBenefit(redeemedBenefit)),
)

// We check if the user has a redeemed stripe promotion and whether the promotion will be kept when
// tier is changed. If the 'stripe_on_change' key doesn't exist then we assume the promotion will be
// kept per server specs
export const willKeepBenefits$ = /*@__PURE__*/ createSelector(
	redeemedBenefit$,
	nextSKU$,
	subscribingSKU$,
	(redeemedBenefit, nextSKU, subscribingSKU) => {
		if (!redeemedBenefit) return false

		if (isStripeCouponBenefit(redeemedBenefit)) {
			return (redeemedBenefit.details.stripe_on_change || 'keep') == 'keep'
		}

		if (isExtendedTrialBenefit(redeemedBenefit)) {
			const stripeChange = redeemedBenefit.details.stripe_on_change || 'keep'

			if (stripeChange == 'keep') return true
			// Keep benefit when downgrading
			if (
				stripeChange.includes('keep_downgrade') &&
				subscribingSKU &&
				subscribingSKU.weight <= nextSKU.weight
			) {
				return true
			}
		}
	},
)
