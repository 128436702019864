import { durationAs } from '@eturi/date-util'
import { curry } from '@eturi/util'
import {
	type AccountBenefit,
	accountTz$,
	activeBenefit$,
	availableBenefit$,
	intToFloatPrice,
	isAppleSub$,
	isExtendedTrialBenefit,
	isExtendedTrialBenefitDetails,
	isGoogleSub$,
	isPaidSub$,
	isStripeCouponBenefit,
	mapTierToFullName,
	mapTierToName,
	nextSKU$,
	nextSKUSource$,
	type SKUDef,
	skuDefs$,
	stripeSKUDefs$,
	stripeSKUPrem$,
	type SubCycle,
	type TierDef,
	toPriceStr,
} from '@op/services'
import { createSelector } from '@reduxjs/toolkit'
import type { Moment } from 'moment-timezone'
import moment from 'moment-timezone'
import { isUISelectedAnnual$, subscribingCycle$, subscribingTier$ } from '../reducers/product.slice'

const _daysFromNow = (m: Maybe<Moment>) => (m ? Math.ceil(m.diff(Date.now(), 'days', true)) : 0)

export const subscribingPlatform$ = /*@__PURE__*/ createSelector(
	nextSKUSource$,
	subscribingTier$,
	(nextSource, subscribingTier): 'stripe' | null =>
		// Tier needs to be set, obviously
		(
			!subscribingTier ||
			// Apple and Google are not managed in app
			nextSource === 'apple' ||
			nextSource === 'google'
		) ?
			null
		:	'stripe',
)

export const subscribingSKU$ = /*@__PURE__*/ createSelector(
	skuDefs$,
	subscribingPlatform$,
	subscribingTier$,
	subscribingCycle$,
	(defs, platform, tierDef, cycle): Maybe<SKUDef> => {
		if (!platform) return null

		return defs.find(
			(def) =>
				def.billing_cycle === cycle &&
				def.tier_def === tierDef &&
				def.purchase_platforms.includes(platform) &&
				def.purchasable_with.includes(platform),
		)
	},
)

// NOTE: We normalize this to a string b/c we set the tier for downgrade and
//  purchase states, so this should always exist there. If we need to use this
//  otherwise, we can do a boolean cast on it.
export const subscribingId$ = /*@__PURE__*/ createSelector(
	subscribingSKU$,
	(s): string => s?.sku || '',
)

export const offerExpiryMoment$ = /*@__PURE__*/ createSelector(
	accountTz$,
	availableBenefit$,
	(accountTz, availableBenefit): Maybe<Moment> => {
		// There's no offer to expire so return current moment
		if (!(isExtendedTrialBenefit(availableBenefit) || isStripeCouponBenefit(availableBenefit)))
			return moment()

		const {
			account: { applied_ts },
			details,
		} = availableBenefit
		let availableSecs

		// If we don't have a truthy applied_ts, and available_secs is not truthy,
		// we can't derive a meaningful expiry.
		if (!(applied_ts && (availableSecs = details.available_secs))) return

		// The amount of time elapsed since the benefit was applied
		const elapsedSecs = durationAs(Date.now() - applied_ts, 'ms', 's')
		const remainingSecs = availableSecs - elapsedSecs

		return moment.utc().add(remainingSecs, 'seconds').tz(accountTz)
	},
)

// When a coupon has more than 1 benefit it will have 'sku_benefits' in it that has specific details
// for that sku, otherwise the benefits for that coupon are top level
const getBenefitDetailsPercent = (benefit: Maybe<AccountBenefit>, sku: string) => {
	if (!(benefit && isStripeCouponBenefit(benefit) && benefit.details.limit_skus?.includes(sku)))
		return 0

	const skuBenefits = benefit.details.sku_benefits?.[sku]
	return (skuBenefits || benefit.details).stripe_percent_off
}

const getBenefitDetailsDuration = (benefit: Maybe<AccountBenefit>, sku: string) => {
	if (!(benefit && isExtendedTrialBenefit(benefit) && benefit.details.limit_skus?.includes(sku)))
		return 0

	const skuBenefits = benefit.details.sku_benefits?.[sku]
	return (skuBenefits || benefit.details).trial_secs
}

export const selectedStripeCouponPercent$ = /*@__PURE__*/ createSelector(
	availableBenefit$,
	subscribingSKU$,
	(benefit, subSKU) => {
		if (!subSKU) return 0

		return getBenefitDetailsPercent(benefit, subSKU.sku)
	},
)

const DEFAULT_TRIAL_DURATION = 14

// NOTE: It was agreed upon in #dev channel on 4/1 at 16:31 that the trial
//  duration can be derived from the Stripe Premium SKU because, for now, all
//  SKUs will have the same trial duration.
const trialDurationInDaysRaw$ = /*@__PURE__*/ createSelector(
	availableBenefit$,
	subscribingSKU$,
	stripeSKUPrem$,
	(benefit, subscribingSKU, stripeSKUPrem): number => {
		if (!subscribingSKU) return DEFAULT_TRIAL_DURATION

		const details = benefit?.details

		if (!(details && isExtendedTrialBenefitDetails(details))) return DEFAULT_TRIAL_DURATION

		const couponTrialSec = getBenefitDetailsDuration(benefit, subscribingSKU.sku)

		return couponTrialSec ?
				durationAs(couponTrialSec, 's', 'd')
			:	subscribingSKU?.trial_days || stripeSKUPrem?.trial_days || DEFAULT_TRIAL_DURATION
	},
)

const trialExpiryMoment$ = /*@__PURE__*/ createSelector(
	accountTz$,
	trialDurationInDaysRaw$,
	(tz, trialDurationInDays) => moment.utc().add(trialDurationInDays, 'days').tz(tz),
)

// NOTE: a benefit can have multiple skus available, in which case the percent_off
//  might differ so we look at the specific sku benefit first then default it to
//  the base stripe_percent_off
const toCyclePriceBenefitStr = (activeBenefit: Maybe<AccountBenefit>, def: SKUDef) => {
	const benefitPercentage = getBenefitDetailsPercent(activeBenefit, def.sku)

	const discountMod = benefitPercentage ? (100 - benefitPercentage) / 100 : 1

	return toPriceStr(intToFloatPrice(discountMod * def.price_usd))
}
const toMonthlyPriceBenefitStr = (activeBenefit: Maybe<AccountBenefit>, def: SKUDef) => {
	const benefitPercentage = getBenefitDetailsPercent(activeBenefit, def.sku)

	const discountMod = benefitPercentage ? (100 - benefitPercentage) / 100 : 1

	return toPriceStr(
		intToFloatPrice((discountMod * def.price_usd) / (def.billing_cycle === 'annual' ? 12 : 1)),
	)
}

const toCyclePriceStr = (def: SKUDef) => toPriceStr(intToFloatPrice(def.price_usd))
const toMonthlyPriceStr = (def: SKUDef) =>
	toPriceStr(intToFloatPrice(def.price_usd / (def.billing_cycle === 'annual' ? 12 : 1)))

const toSKUCyclePercent = (benefit: Maybe<AccountBenefit>, def: SKUDef) =>
	getBenefitDetailsPercent(benefit, def.sku)

const toSKUCycleTrialDays = (benefit: Maybe<AccountBenefit>, def: SKUDef) => {
	const trialSecs = getBenefitDetailsDuration(benefit, def.sku)
	return durationAs(trialSecs, 's', 'd')
}

export const isPromoTrialDuration$ = /*@__PURE__*/ createSelector(
	trialDurationInDaysRaw$,
	(t) => t > DEFAULT_TRIAL_DURATION,
)
export const offerExpiryInDays$ = /*@__PURE__*/ createSelector(offerExpiryMoment$, _daysFromNow)
export const trialDurationInDays$ = /*@__PURE__*/ createSelector(trialExpiryMoment$, _daysFromNow)
export const trialExpiryDate$ = /*@__PURE__*/ createSelector(trialExpiryMoment$, (m) =>
	m.format('L'),
)

const _stripeSKUForTier = curry(
	(tierDef: TierDef, stripeSKUDefs: SKUDef[], isUISelectedAnnual: boolean) => {
		const billingCycle: SubCycle = isUISelectedAnnual ? 'annual' : 'monthly'

		return stripeSKUDefs.find((d) => d.tier_def === tierDef && d.billing_cycle === billingCycle)!
	},
)

const _stripeSKUPrem$ = /*@__PURE__*/ createSelector(
	stripeSKUDefs$,
	isUISelectedAnnual$,
	_stripeSKUForTier('premium'),
)
const _stripeSKUPremPlus$ = /*@__PURE__*/ createSelector(
	stripeSKUDefs$,
	isUISelectedAnnual$,
	_stripeSKUForTier('premium_plus'),
)

// The following selectors get the annual price regardless of ui state
const _stripeSKUAnnualPrem$ = /*@__PURE__*/ createSelector(
	stripeSKUDefs$,
	() => true,
	_stripeSKUForTier('premium'),
)
const _stripeSKUAnnualPremPlus$ = /*@__PURE__*/ createSelector(
	stripeSKUDefs$,
	() => true,
	_stripeSKUForTier('premium_plus'),
)

const _stripeSKUMonthPrem$ = /*@__PURE__*/ createSelector(
	stripeSKUDefs$,
	() => false,
	_stripeSKUForTier('premium'),
)
const _stripeSKUMonthPremPlus$ = /*@__PURE__*/ createSelector(
	stripeSKUDefs$,
	() => false,
	_stripeSKUForTier('premium_plus'),
)

// With Benefits Applied
export const cyclePriceStrPremBenefitMonth$ = /*@__PURE__*/ createSelector(
	activeBenefit$,
	_stripeSKUMonthPrem$,
	toCyclePriceBenefitStr,
)

export const cyclePriceStrPremPlusBenefitMonth$ = /*@__PURE__*/ createSelector(
	activeBenefit$,
	_stripeSKUMonthPremPlus$,
	toCyclePriceBenefitStr,
)

export const cyclePriceStrPremBenefitAnnual$ = /*@__PURE__*/ createSelector(
	activeBenefit$,
	_stripeSKUAnnualPrem$,
	toCyclePriceBenefitStr,
)
export const cyclePriceStrPremPlusBenefitAnnual$ = /*@__PURE__*/ createSelector(
	activeBenefit$,
	_stripeSKUAnnualPremPlus$,
	toCyclePriceBenefitStr,
)
export const cyclePriceStrPremAnnualBenefitMonthly$ = /*@__PURE__*/ createSelector(
	activeBenefit$,
	_stripeSKUAnnualPrem$,
	toMonthlyPriceBenefitStr,
)
export const cyclePriceStrPremPlusAnnualBenefitMonthly$ = /*@__PURE__*/ createSelector(
	activeBenefit$,
	_stripeSKUAnnualPremPlus$,
	toMonthlyPriceBenefitStr,
)

// SKU Percentage based on available benefits
export const cyclePremMonthlyPercent$ = /*@__PURE__*/ createSelector(
	activeBenefit$,
	_stripeSKUMonthPrem$,
	toSKUCyclePercent,
)

export const cyclePremAnnualPercent$ = /*@__PURE__*/ createSelector(
	activeBenefit$,
	_stripeSKUAnnualPrem$,
	toSKUCyclePercent,
)

export const cyclePremPlusMonthlyPercent$ = /*@__PURE__*/ createSelector(
	activeBenefit$,
	_stripeSKUMonthPremPlus$,
	toSKUCyclePercent,
)

export const cyclePremPlusAnnualPercent$ = /*@__PURE__*/ createSelector(
	activeBenefit$,
	_stripeSKUAnnualPremPlus$,
	toSKUCyclePercent,
)

export const cyclePremMonthlyTrialDuration$ = /*@__PURE__*/ createSelector(
	activeBenefit$,
	_stripeSKUMonthPrem$,
	toSKUCycleTrialDays,
)
export const cyclePremAnnualTrialDuration$ = /*@__PURE__*/ createSelector(
	activeBenefit$,
	_stripeSKUAnnualPrem$,
	toSKUCycleTrialDays,
)
export const cyclePremPlusMonthlyTrialDuration$ = /*@__PURE__*/ createSelector(
	activeBenefit$,
	_stripeSKUMonthPremPlus$,
	toSKUCycleTrialDays,
)
export const cyclePremPlusAnnualTrialDuration$ = /*@__PURE__*/ createSelector(
	activeBenefit$,
	_stripeSKUAnnualPremPlus$,
	toSKUCycleTrialDays,
)

// Without Benefits Applied
export const cyclePriceStrPremMonth$ = /*@__PURE__*/ createSelector(
	_stripeSKUMonthPrem$,
	toCyclePriceStr,
)
export const cyclePriceStrPremPlusMonth$ = /*@__PURE__*/ createSelector(
	_stripeSKUMonthPremPlus$,
	toCyclePriceStr,
)

export const cyclePriceStrPremAnnual$ = /*@__PURE__*/ createSelector(
	_stripeSKUAnnualPrem$,
	toCyclePriceStr,
)
export const cyclePriceStrPremPlusAnnual$ = /*@__PURE__*/ createSelector(
	_stripeSKUAnnualPremPlus$,
	toCyclePriceStr,
)

export const cyclePriceStrPrem$ = /*@__PURE__*/ createSelector(_stripeSKUPrem$, toCyclePriceStr)
export const cyclePriceStrPremPlus$ = /*@__PURE__*/ createSelector(
	_stripeSKUPremPlus$,
	toCyclePriceStr,
)
export const monthlyPriceStrPrem$ = /*@__PURE__*/ createSelector(_stripeSKUPrem$, toMonthlyPriceStr)
export const monthlyPriceStrPremPlus$ = /*@__PURE__*/ createSelector(
	_stripeSKUPremPlus$,
	toMonthlyPriceStr,
)

export const isSubscribingStripe$ = /*@__PURE__*/ createSelector(
	subscribingPlatform$,
	(p) => p === 'stripe',
)
export const subscribingName$ = /*@__PURE__*/ createSelector(subscribingTier$, mapTierToName)
export const subscribingFullName$ = /*@__PURE__*/ createSelector(
	subscribingTier$,
	mapTierToFullName,
)

export const isChangingCycle$ = /*@__PURE__*/ createSelector(
	nextSKU$,
	subscribingSKU$,
	(nS, sS) => nS.billing_cycle !== sS?.billing_cycle,
)

export const isChangingTier$ = /*@__PURE__*/ createSelector(
	nextSKU$,
	subscribingSKU$,
	(nS, sS) => nS.tier_def !== sS?.tier_def,
)

export const isChangingCycleOnly$ = /*@__PURE__*/ createSelector(
	isChangingCycle$,
	isChangingTier$,
	(isChangingCycle, isChangingTier) => isChangingCycle && !isChangingTier,
)

export const hasExternalPayment$ = /*@__PURE__*/ createSelector(
	isAppleSub$,
	isGoogleSub$,
	isPaidSub$,
	(isApple, isGoogle, isPaid) => isPaid && (isApple || isGoogle),
)
