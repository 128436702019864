import { useRefGetter, useThrottle, useWindowEvent } from '@eturi/react'
import { accountId$, isAuthenticated$ } from '@op/services'
import { setTag, setUser } from '@sentry/react'
import cls from 'classnames'
import { memo, useEffect, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { identify } from './analytics'
import { AppPages } from './AppPages'
import { Footer } from './components'
import { Ably, Chat, EmailVerificationModal, GuardModal, ProductModal } from './components/lazy'
import { isMenuView$ } from './compound-selectors/app-misc'
import { currentLang$ } from './compound-selectors/language'
import {
	hasPairPageStyles$,
	isPromoPage$,
	isSignupPage$,
	showFooterInMobile$,
} from './compound-selectors/routes'
import { ZAppContent, ZOverlayAppSibling } from './constants/z-index'
import { topNavLG, topNavSM } from './css-variables'
import { useBodyClass, useMediaMatches } from './hooks'
import { showMobileManageTabs$ } from './reducers/app-misc.slice'
import { LoadEmpty, NavSlideBox, Overlay } from './widgets'

const AppMain = () => {
	const [getAppHeight, setAppHeight] = useRefGetter(0)
	const accountId = useSelector(accountId$)
	const currentLang = useSelector(currentLang$)
	const isAuthenticated = useSelector(isAuthenticated$)
	const isMenuView = useSelector(isMenuView$)
	const mediaMatches = useMediaMatches()
	const showMobileMenuTabs = useSelector(showMobileManageTabs$)
	const showFooterInMobile = useSelector(showFooterInMobile$)
	const isSignUpPage = useSelector(isSignupPage$)
	const isPromoPage = useSelector(isPromoPage$)

	// Footer should only be shown for Web desktop view, and apparently, not when affiliate stuff is shown
	const canShowFooter = showFooterInMobile || (mediaMatches.md && !(isSignUpPage || isPromoPage))

	// NOTE: If we need to add an iOS discriminator class this is what we'll use.
	//  That code was removed due to refactoring the layouts.
	// const isSupportedIOS = useSelector(isSupportedIOS$);

	const setViewHeight = useThrottle(
		() => {
			const appHeight = window.innerHeight

			if (appHeight === getAppHeight()) return

			document.documentElement.style.setProperty('--vh', `${appHeight / 100}px`)
			setAppHeight(appHeight)
		},
		72,
		{ leading: true, trailing: true },
	)

	useWindowEvent('resize', setViewHeight)
	useWindowEvent('orientationchange', setViewHeight)

	useBodyClass('is-pair', useSelector(hasPairPageStyles$))

	// Set the view height for our ghetto layout
	useLayoutEffect(setViewHeight, [])

	useEffect(() => {
		window.OURPACT.version = process.env.APP_VERSION
	}, [])

	// Set or clear Sentry user based on account id.
	useEffect(() => {
		setUser(accountId ? { id: accountId } : null)
		if (accountId) identify(accountId)
	}, [accountId])

	// Set a tag for the current language
	useEffect(() => {
		setTag('lang', currentLang)
	}, [currentLang])

	return (
		<>
			<NavSlideBox
				className={cls('op-app-content', showMobileMenuTabs && 'pb-16')}
				style={{
					paddingTop:
						isMenuView ?
							mediaMatches.lg ?
								topNavLG
							:	topNavSM
						:	undefined,
					zIndex: ZAppContent,
				}}
			>
				<AppPages />

				{isAuthenticated && (
					<>
						<LoadEmpty>
							<Ably />
						</LoadEmpty>

						<LoadEmpty>
							<EmailVerificationModal />
						</LoadEmpty>

						<LoadEmpty>
							<GuardModal />
						</LoadEmpty>

						<LoadEmpty>
							<ProductModal />
						</LoadEmpty>
					</>
				)}

				{canShowFooter && <Footer />}

				<Overlay />
			</NavSlideBox>

			{!mediaMatches.lg && <Overlay zIndex={ZOverlayAppSibling} />}

			<LoadEmpty>
				<Chat />
			</LoadEmpty>
		</>
	)
}

export default /*@__PURE__*/ memo(AppMain)
