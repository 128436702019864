import { createSentryReduxEnhancer } from '@eturi/sentry'
import {
	accessSlice,
	accountSlice,
	appsSlice,
	blockStatusSlice,
	createPersistenceEnhancer,
	createRehydrateAuthState,
	deviceSlice,
	geoSlice,
	httpUnauthorizedAction,
	notificationSlice,
	persistSlice,
	purchaseSlice,
	resetAction,
	ruleSlice,
	userSlice,
} from '@op/services'
import { configureStore } from '@reduxjs/toolkit'
import { analyticsLogout, trackWithState } from './analytics'
import { asyncSessionStorage } from './async-storage'
import { SessionDeviceId } from './env'
import { http } from './http'
import { rootReducer, type RootState, sentryTransformer } from './reducers'
import { appMiscSlice } from './reducers/app-misc.slice'
import { chatSlice } from './reducers/chat.slice'
import { galleryUISlice } from './reducers/gallery-ui.slice'
import { geoUISlice, rehydrateGeoUIState } from './reducers/geo-ui.slice'
import { pairSlice } from './reducers/pair.slice'
import { decryptPrivateKey } from './util/crypto'

const handleCustomActionsMiddleware =
	(api: { getState: () => RootState }) => (next: any) => (action: any) => {
		if (httpUnauthorizedAction.match(action)) return next(resetAction('auth_401'))

		if (resetAction.match(action)) {
			const resetEventAction = action.payload

			if (resetEventAction != null) {
				trackWithState(api.getState(), 'Logout', { action: resetEventAction })
				analyticsLogout()
			}

			SessionDeviceId.clear()
		}

		return next(action)
	}

export const store = /*@__PURE__*/ configureStore({
	enhancers: (getDefaultEnhancers) => {
		return getDefaultEnhancers().concat(
			createSentryReduxEnhancer(sentryTransformer) as any,
			createPersistenceEnhancer(
				[
					// NOTE: Yes this is a lot of keys to persist. I tested an implementation of persistence
					//  enhancer that uses `blockList`, but realized that this adds overhead due to the fact that
					//  we would have to be aware that, when adding any new key to a reducer, we make sure we're
					//  not using `blockList` instead of `allowList`. While this may seem similar to the overhead
					//  of `allowList`, it's more problematic from a security perspective to store a superfluous
					//  key, than to omit one.
					persistSlice(accountSlice, {
						allowList: [
							'account',
							'accountId',
							'email',
							'encryptedPrivate',
							'isInit',
							'isLegacy',
							'parentSecret',
							'parentSecretId',
							'token',
							'tokenTS',
							'version',
							// Save password in dev environments. We have an audit script that will detect this and
							// make sure it's absent in production builds.
							...(process.env.APP_ENV === 'dev' && !location.search.includes('no-pw') ?
								(['password'] as const)
							:	[]),
						],
						rehydrate: createRehydrateAuthState(decryptPrivateKey),
					}),
					persistSlice(accessSlice, { blockList: ['hasAccountMismatch', 'paymentSource'] }),
					persistSlice(appMiscSlice, {
						allowList: [
							'redirect',
							'signUpGCLID',
							'wasAllowanceWelcomeModalShown',
							'wasVewInstructionsModalShown',
						],
					}),
					persistSlice(appsSlice, { allowList: ['appDetailsMap'] }),
					persistSlice(blockStatusSlice),
					persistSlice(chatSlice, { allowList: ['isEnabled'] }),
					persistSlice(deviceSlice),
					persistSlice(galleryUISlice, { allowList: ['askOnDelete'] }),
					// FIXME: See if we can persist more, including isInit
					persistSlice(geoSlice, { allowList: ['locationAddressMap'] }),
					persistSlice(geoUISlice, {
						allowList: [
							'deselectedUserIds',
							'isLocationHistoryEnabled',
							'isPlacesVisible',
							'mapTypeId',
						],
						rehydrate: rehydrateGeoUIState,
					}),
					persistSlice(notificationSlice),
					persistSlice(pairSlice),
					persistSlice(purchaseSlice),
					persistSlice(ruleSlice),
					persistSlice(userSlice),
				],
				asyncSessionStorage,
			),
		)
	},

	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			thunk: { extraArgument: { http } },
			serializableCheck: false,
		}).prepend(handleCustomActionsMiddleware) as any,

	reducer: rootReducer,
})
