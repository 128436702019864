import { useT } from '@eturi/react-i18next'
import { PREM_PLUS_NAME } from '@op/services'
import { lazy, memo } from 'react'
import { useSelector } from 'react-redux'
import { trialDurationInDays$ } from '../../compound-selectors/product'
import { useSearchParamsAndClear } from '../../hooks/useSearchParamsAndClear'
import type { SubscriptionType } from '../../types'
import { isSubscriptionType } from '../../types'
import { LoadPage, NavBar } from '../../widgets'

// We normalize the type in case someone accesses the url directly without
// query params
const normalizeSubType = (type: Maybe<string>): SubscriptionType =>
	isSubscriptionType(type) ? type : 'options'

const SubscriptionPageContent = lazy(() => import('./SubscriptionPageContent'))

const SubscriptionPage = () => {
	const [t] = useT()
	const trialDurationInDays = useSelector(trialDurationInDays$)

	const [chooseSubType, goBack] = useSearchParamsAndClear((params) => [
		normalizeSubType(params.get('chooseSubType')),
		params.get('goBack') === '1',
	])

	const PageTitle = (() => {
		switch (chooseSubType) {
			case 'options':
				return t('subscription.subscription_options')

			case 'prem_plus':
				return t('actions.upgrade_to_tier', { tier: PREM_PLUS_NAME })

			case 'prem_plus_trial':
			case 'trial':
				if (trialDurationInDays > 60) {
					return t('subscription.title_month_trial', {
						trialMonths: Math.floor(trialDurationInDays / 30),
					})
				}
				return t('subscription.title_trial', { trialDays: trialDurationInDays })

			case 'upgrade':
				return t('subscription.upgrade_options')
		}
	})()

	return (
		<article className="page is-page-pad-touch">
			<NavBar.Default goBack={goBack}>{PageTitle}</NavBar.Default>

			<LoadPage>
				<SubscriptionPageContent type={chooseSubType} />
			</LoadPage>
		</article>
	)
}

export default /*@__PURE__*/ memo(SubscriptionPage)
