import {
	activeBenefit$,
	fetchCouponDetails,
	fetchGroupDetails,
	fetchRedeemedCouponDetails,
	type HttpExtra,
	isExtendedTrialBenefit,
	unwrapThunks,
} from '@op/services'
import { getEndpoints } from '../env'
import type { OPThunkP } from '../types'

export const fetchBenefitsAndDetails =
	(extra?: HttpExtra): OPThunkP =>
	async (dispatch, getState) => {
		try {
			await Promise.all(
				unwrapThunks([
					dispatch(fetchCouponDetails(extra)),
					dispatch(fetchRedeemedCouponDetails(extra)),
				]),
			)

			const state = getState()
			// FIXME: Product needs to flesh out which should be priority, what if they have redeemed
			//  a coupon and have a different one also available. Right now we choose one and prioritize
			//  redeemed since it's active
			const activeBenefit = activeBenefit$(state)

			let benefitGroupId = activeBenefit?.account?.coupon_group_id

			// For extended trial once applied to the account it will not have coupon_group_id
			// but the group id will be in the limit_group property
			if (!benefitGroupId && isExtendedTrialBenefit(activeBenefit)) {
				benefitGroupId = activeBenefit.details.limit_groups?.[0]
			}

			// If an available coupon or a redeemed coupon is associated with a group we pull down those
			// group benefit details
			if (benefitGroupId) {
				await dispatch(
					fetchGroupDetails({
						groupId: benefitGroupId,
						sfsh: getEndpoints()!.sfsh,
					}),
				).unwrap()
			}
		} catch {
			//
		}
	}
