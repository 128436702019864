import { omit } from '@eturi/util'
import type { Lang } from '@op/services'
import { resetAction, truncateIdAction } from '@op/services'
import { keysToInt } from '@op/util'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'
import without from 'lodash/without'
import { createSliceTransformer } from 'rtk-slice-transformer'
import type { AppRuleSortType, ManageRoutePath } from '../types'
import type { EmailVerifyType } from '../types/EmailVerification'
import type { GuardModalType } from '../types/GuardModalType'
import { pairExpiredLogout } from './pair.slice'

export type AppMiscState = {
	readonly appRulesSortType: AppRuleSortType
	readonly canShowMenuView: boolean
	readonly didShowPostSignUp: boolean
	readonly emailVerifyType: Maybe<EmailVerifyType>
	readonly guardModalType: Maybe<GuardModalType>
	readonly manageFeature: ManageRoutePath
	readonly momentLocale: Lang
	readonly redirect: Maybe<string>
	readonly scrollFrozenIds: string[]
	readonly selectedNotifId: Maybe<string>
	readonly showMobileManageTabs: boolean
	readonly signUpGCLID: Maybe<string>
	readonly wasAllowanceWelcomeModalShown: boolean
	readonly wasVewInstructionsModalShown: boolean
}

export type WithAppMiscState = {
	readonly appMisc: AppMiscState
}

const initialState: AppMiscState = {
	appRulesSortType: 'alphabetical',
	canShowMenuView: false,
	// This is only set to false if we do actually sign up.
	didShowPostSignUp: true,
	emailVerifyType: null,
	guardModalType: null,
	manageFeature: 'schedule',
	momentLocale: 'en',
	redirect: null,
	scrollFrozenIds: [],
	selectedNotifId: null,
	showMobileManageTabs: false,
	signUpGCLID: null,
	wasAllowanceWelcomeModalShown: false,
	wasVewInstructionsModalShown: false,
}

export const appMiscSlice = createSlice({
	name: 'appMisc',
	initialState,
	reducers: {
		_addScrollFrozenId(s, a: PayloadAction<string>) {
			s.scrollFrozenIds.push(a.payload)
		},

		_removeScrollFrozenId(s, a: PayloadAction<string>) {
			s.scrollFrozenIds = without(s.scrollFrozenIds, a.payload)
		},

		setAppRulesSortType(s, a: PayloadAction<AppRuleSortType>) {
			s.appRulesSortType = a.payload
		},

		setActiveManageFeature(s, a: PayloadAction<ManageRoutePath>) {
			s.manageFeature = a.payload
		},

		setAllowanceWelcomeModalShown(s, a: PayloadAction<boolean>) {
			s.wasAllowanceWelcomeModalShown = a.payload
		},

		setCanShowMenuView(s, a: PayloadAction<boolean>) {
			s.canShowMenuView = a.payload
		},

		setEmailVerify(s, a: PayloadAction<Maybe<EmailVerifyType>>) {
			s.emailVerifyType = a.payload
		},

		setGuardModalType(s, a: PayloadAction<Maybe<GuardModalType>>) {
			s.guardModalType = a.payload
		},

		setMobileManageTabsVisible(s, a: PayloadAction<boolean>) {
			s.showMobileManageTabs = a.payload
		},

		setMomentLocale(s, a: PayloadAction<Lang>) {
			s.momentLocale = a.payload
		},

		setPostSignUpShown(s, a: PayloadAction<boolean>) {
			s.didShowPostSignUp = a.payload
		},

		setRedirect(s, a: PayloadAction<Maybe<string>>) {
			s.redirect = a.payload
		},

		setSelectedNotifId(s, a: PayloadAction<Maybe<string>>) {
			s.selectedNotifId = a.payload
		},

		setSignUpGCLID(s, a: PayloadAction<Maybe<string>>) {
			s.signUpGCLID = a.payload
		},

		setVewInstructionsModalShown(s, a: PayloadAction<boolean>) {
			s.wasVewInstructionsModalShown = a.payload
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(resetAction, () => initialState)
			.addCase(pairExpiredLogout.fulfilled, (s, a) => {
				s.redirect = a.payload.redirect
			}),
})

export const {
	setActiveManageFeature,
	setAllowanceWelcomeModalShown,
	setAppRulesSortType,
	setCanShowMenuView,
	setEmailVerify,
	setGuardModalType,
	setMobileManageTabsVisible,
	setMomentLocale,
	setPostSignUpShown,
	setRedirect,
	setSelectedNotifId,
	setSignUpGCLID,
	setVewInstructionsModalShown,
} = appMiscSlice.actions

const isTruncatedIdAction = isAnyOf(
	appMiscSlice.actions._addScrollFrozenId,
	appMiscSlice.actions._removeScrollFrozenId,
)

export const appMiscSliceTransformer = /*@__PURE__*/ createSliceTransformer(
	appMiscSlice,
	(s) => ({
		...omit(s, ['momentLocale']),
		...keysToInt(s, ['scrollFrozenIds']),
	}),
	(a) => {
		if (setGuardModalType.match(a)) return a
		if (isTruncatedIdAction(a)) return truncateIdAction(a)

		return null
	},
)

////////// Selectors ///////////////////////////////////////////////////////////
const state$ = <T extends WithAppMiscState>(s: T) => s.appMisc

export const appRulesSortType$ = /*@__PURE__*/ createSelector(state$, (s) => s.appRulesSortType)
export const canShowMenuView$ = /*@__PURE__*/ createSelector(state$, (s) => s.canShowMenuView)
export const didShowPostSignUp$ = /*@__PURE__*/ createSelector(state$, (s) => s.didShowPostSignUp)
export const emailVerificationModalType$ = /*@__PURE__*/ createSelector(
	state$,
	(s) => s.emailVerifyType,
)
export const guardModalType$ = /*@__PURE__*/ createSelector(state$, (s) => s.guardModalType)
export const isScrollFrozen$ = /*@__PURE__*/ createSelector(
	state$,
	(s) => !isEmpty(s.scrollFrozenIds),
)
export const manageFeature$ = /*@__PURE__*/ createSelector(state$, (s) => s.manageFeature)
export const momentLocale$ = /*@__PURE__*/ createSelector(state$, (s) => s.momentLocale)
export const rawRedirect$ = /*@__PURE__*/ createSelector(state$, (s) => s.redirect)
export const selectedNotifId$ = /*@__PURE__*/ createSelector(state$, (s) => s.selectedNotifId)
export const showMobileManageTabs$ = /*@__PURE__*/ createSelector(
	state$,
	(s) => s.showMobileManageTabs,
)
export const signUpGCLID$ = /*@__PURE__*/ createSelector(state$, (s) => s.signUpGCLID)
export const wasAllowanceWelcomeModalShown$ = /*@__PURE__*/ createSelector(
	state$,
	(s) => s.wasAllowanceWelcomeModalShown,
)
export const wasVewInstructionsModalShown$ = /*@__PURE__*/ createSelector(
	state$,
	(s) => s.wasVewInstructionsModalShown,
)
